// extracted by mini-css-extract-plugin
export var root = "PlasmicBlogPost-module--root--2t8Gz";
export var root__long = "PlasmicBlogPost-module--root__long--1d75w";
export var freeBox__pRnqi = "PlasmicBlogPost-module--freeBox__pRnqi--3U2wI";
export var img__xLc12 = "PlasmicBlogPost-module--img__xLc12--2kVDz";
export var __wab_imgSpacer = "PlasmicBlogPost-module--__wab_img-spacer--1PpUP";
export var freeBox__vX0Me = "PlasmicBlogPost-module--freeBox__vX0Me--2FJCj";
export var freeBox__long__vX0MeKqLpw = "PlasmicBlogPost-module--freeBox__long__vX0MeKqLpw--3Rcyx";
export var slotTargetTitle = "PlasmicBlogPost-module--slotTargetTitle--3LY0r";
export var slotTargetAuthor = "PlasmicBlogPost-module--slotTargetAuthor--dR7O9";
export var slotTargetDescription = "PlasmicBlogPost-module--slotTargetDescription--1XJ3I";
export var slotTargetDescription__long = "PlasmicBlogPost-module--slotTargetDescription__long--2hW7E";