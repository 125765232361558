// extracted by mini-css-extract-plugin
export var root = "PlasmicLinkButton-module--root--1HcBJ";
export var root__type_solidOrange = "PlasmicLinkButton-module--root__type_solidOrange--3OTuI";
export var root__type_solidGray = "PlasmicLinkButton-module--root__type_solidGray--382Uc";
export var root__type_outlineGray = "PlasmicLinkButton-module--root__type_outlineGray--fMctz";
export var root__size_small = "PlasmicLinkButton-module--root__size_small--3gW0s";
export var root__type_blankOrange = "PlasmicLinkButton-module--root__type_blankOrange--1TON6";
export var slotTargetText = "PlasmicLinkButton-module--slotTargetText--3SHOJ";
export var slotTargetText__type_solidOrange = "PlasmicLinkButton-module--slotTargetText__type_solidOrange--1TrlY";
export var slotTargetText__type_solidGray = "PlasmicLinkButton-module--slotTargetText__type_solidGray--2igEq";
export var slotTargetText__type_blankOrange = "PlasmicLinkButton-module--slotTargetText__type_blankOrange--fLqbR";
export var slotTargetText__type_outlineGray = "PlasmicLinkButton-module--slotTargetText__type_outlineGray--qAQ9U";
export var slotTargetText__type_blankGray = "PlasmicLinkButton-module--slotTargetText__type_blankGray--3Fo8M";
export var root__type_blankGray = "PlasmicLinkButton-module--root__type_blankGray--xD30I";