// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureItem-module--root--2KS9B";
export var freeBox__vqW3P = "PlasmicFeatureItem-module--freeBox__vqW3P--36qyv";
export var freeBox__r1BM = "PlasmicFeatureItem-module--freeBox__r1BM--2s-Q8";
export var freeBox__lkaYp = "PlasmicFeatureItem-module--freeBox__lkaYp--2CDU5";
export var freeBox__kIfdd = "PlasmicFeatureItem-module--freeBox__kIfdd--Cjvr-";
export var text__kbaRc = "PlasmicFeatureItem-module--text__kbaRc--3rA6h";
export var text__jiFmm = "PlasmicFeatureItem-module--text__jiFmm--2HMZO";
export var freeBox__fsXmq = "PlasmicFeatureItem-module--freeBox__fsXmq--d7Y0H";
export var text__arHr0 = "PlasmicFeatureItem-module--text__arHr0--3mv0y";
export var text__qsNg5 = "PlasmicFeatureItem-module--text__qsNg5--luCRj";
export var freeBox__fZjLf = "PlasmicFeatureItem-module--freeBox__fZjLf--2m0YS";
export var linkButton = "PlasmicFeatureItem-module--linkButton--1hQsy";