// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCard-module--root--3ESX2";
export var freeBox___2HLLp = "PlasmicProductCard-module--freeBox___2HLLp--2aFJ7";
export var img__hHg6U = "PlasmicProductCard-module--img__hHg6U--1_iTy";
export var __wab_imgSpacer = "PlasmicProductCard-module--__wab_img-spacer--1cgMI";
export var freeBox___8ZQar = "PlasmicProductCard-module--freeBox___8ZQar--36dUZ";
export var text__aKIxX = "PlasmicProductCard-module--text__aKIxX--4uMVz";
export var freeBox__cj9F = "PlasmicProductCard-module--freeBox__cj9F--B-N2H";
export var svg__kOi30 = "PlasmicProductCard-module--svg__kOi30--RLOob";
export var text___4SpXz = "PlasmicProductCard-module--text___4SpXz--2IDbh";
export var svg___5Xvda = "PlasmicProductCard-module--svg___5Xvda--1yNpc";
export var svg__bVCn = "PlasmicProductCard-module--svg__bVCn--28OCh";
export var ratings__hf0Dk = "PlasmicProductCard-module--ratings__hf0Dk--3Bi2q";
export var freeBox__qzm44 = "PlasmicProductCard-module--freeBox__qzm44--1_Q-2";
export var slotTargetTitle = "PlasmicProductCard-module--slotTargetTitle--1dwHW";
export var slotTargetPrice = "PlasmicProductCard-module--slotTargetPrice--2LtZi";