// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCarousel-module--root--Q-gYm";
export var freeBox__oge7J = "PlasmicProductCarousel-module--freeBox__oge7J--38cy-";
export var freeBox__xu8LK = "PlasmicProductCarousel-module--freeBox__xu8LK--1YkTk";
export var text__ew5CF = "PlasmicProductCarousel-module--text__ew5CF--yHfGc";
export var text__aJrSt = "PlasmicProductCarousel-module--text__aJrSt--2caBx";
export var text__zDQo6 = "PlasmicProductCarousel-module--text__zDQo6--3mTud";
export var freeBox__if3FN = "PlasmicProductCarousel-module--freeBox__if3FN--s5qYu";
export var freeBox__xWHoB = "PlasmicProductCarousel-module--freeBox__xWHoB--2Pnpb";
export var text__aqhnd = "PlasmicProductCarousel-module--text__aqhnd--2oOLp";
export var text__a8Y0 = "PlasmicProductCarousel-module--text__a8Y0--3rbUF";
export var freeBox__vTsfz = "PlasmicProductCarousel-module--freeBox__vTsfz--5FsqH";
export var linkButton___023K = "PlasmicProductCarousel-module--linkButton___023K--35jsJ";
export var linkButton__i6F1H = "PlasmicProductCarousel-module--linkButton__i6F1H--1l8SP";
export var freeBox__yNvdu = "PlasmicProductCarousel-module--freeBox__yNvdu--70Aj_";
export var img = "PlasmicProductCarousel-module--img--2udQ3";
export var __wab_imgSpacer = "PlasmicProductCarousel-module--__wab_img-spacer--3ts-5";
export var freeBox__pnnTc = "PlasmicProductCarousel-module--freeBox__pnnTc--1jslP";
export var svg__nydM4 = "PlasmicProductCarousel-module--svg__nydM4--2k2RJ";
export var svg__htSui = "PlasmicProductCarousel-module--svg__htSui--1WieS";
export var svg___3OAs4 = "PlasmicProductCarousel-module--svg___3OAs4--2s2b-";