// extracted by mini-css-extract-plugin
export var root = "PlasmicHome-module--root--JcOOa";
export var freeBox__zSioG = "PlasmicHome-module--freeBox__zSioG--1Jmji";
export var freeBox__ohHfl = "PlasmicHome-module--freeBox__ohHfl--3_85c";
export var freeBox__h1Id4 = "PlasmicHome-module--freeBox__h1Id4--3hpM-";
export var productCarousel = "PlasmicHome-module--productCarousel--hnCAR";
export var freeBox___4Ijef = "PlasmicHome-module--freeBox___4Ijef--WOTbE";
export var text__tjDmV = "PlasmicHome-module--text__tjDmV--I39a7";
export var freeBox__dr78I = "PlasmicHome-module--freeBox__dr78I--2pK_o";
export var productCard___5J8M5 = "PlasmicHome-module--productCard___5J8M5--P-utD";
export var img__wk7Fw = "PlasmicHome-module--img__wk7Fw--wj_DC";
export var __wab_imgSpacer = "PlasmicHome-module--__wab_img-spacer--2Tr1q";
export var ratings__xuLq = "PlasmicHome-module--ratings__xuLq--r5Sow";
export var productCard___9UzCg = "PlasmicHome-module--productCard___9UzCg--2hZlk";
export var img__msGXr = "PlasmicHome-module--img__msGXr--3qAzk";
export var ratings__rIrEm = "PlasmicHome-module--ratings__rIrEm--wjNhs";
export var productCard__sbyXz = "PlasmicHome-module--productCard__sbyXz--1VfqT";
export var img__fwOsz = "PlasmicHome-module--img__fwOsz--14P7Q";
export var ratings__fGlxA = "PlasmicHome-module--ratings__fGlxA--qzJrP";
export var productCard__ymFz = "PlasmicHome-module--productCard__ymFz--13LcK";
export var img__s4ZFu = "PlasmicHome-module--img__s4ZFu--OLBmY";
export var ratings___3Scq6 = "PlasmicHome-module--ratings___3Scq6--3iONx";
export var freeBox__jQL = "PlasmicHome-module--freeBox__jQL--1WN-5";
export var productCard__aagx7 = "PlasmicHome-module--productCard__aagx7--2vIoo";
export var img___5EbhM = "PlasmicHome-module--img___5EbhM--1GD9J";
export var ratings__y7PxC = "PlasmicHome-module--ratings__y7PxC--3L3_r";
export var productCard__lmsvq = "PlasmicHome-module--productCard__lmsvq--299vA";
export var img__msbFt = "PlasmicHome-module--img__msbFt--2-yg1";
export var ratings__h1B4O = "PlasmicHome-module--ratings__h1B4O--1mOb5";
export var productCard__d85Bu = "PlasmicHome-module--productCard__d85Bu--3Hxx0";
export var img__kx3H = "PlasmicHome-module--img__kx3H--3uQg0";
export var ratings__ghGp = "PlasmicHome-module--ratings__ghGp--2bvB6";
export var productCard__vN3Zn = "PlasmicHome-module--productCard__vN3Zn--1iifn";
export var img__aOz8S = "PlasmicHome-module--img__aOz8S--1_oZH";
export var ratings__gwhNd = "PlasmicHome-module--ratings__gwhNd--3UKv6";
export var text__vqNbC = "PlasmicHome-module--text__vqNbC--14Q_V";
export var featureItem = "PlasmicHome-module--featureItem--28r-E";
export var freeBox__dzqlP = "PlasmicHome-module--freeBox__dzqlP--2BE7A";
export var text__uHJy8 = "PlasmicHome-module--text__uHJy8--1B5HV";
export var freeBox__vbzk = "PlasmicHome-module--freeBox__vbzk--375C3";
export var productCard__ygHaC = "PlasmicHome-module--productCard__ygHaC--2yi6f";
export var text__aHaHq = "PlasmicHome-module--text__aHaHq--1Im4C";
export var text__o1WzE = "PlasmicHome-module--text__o1WzE--2etP-";
export var img__p2Jmc = "PlasmicHome-module--img__p2Jmc--2TEB0";
export var ratings__fRz2P = "PlasmicHome-module--ratings__fRz2P--3sA9D";
export var productCard__sdf7V = "PlasmicHome-module--productCard__sdf7V--2p2b1";
export var img__icJ5W = "PlasmicHome-module--img__icJ5W--3DHoe";
export var ratings__fPd4N = "PlasmicHome-module--ratings__fPd4N--1hbiu";
export var productCard__pzPcO = "PlasmicHome-module--productCard__pzPcO--1OeCc";
export var img__raWnC = "PlasmicHome-module--img__raWnC--3MP27";
export var ratings___5IJ9U = "PlasmicHome-module--ratings___5IJ9U--2EGUn";
export var productCard__ctUi5 = "PlasmicHome-module--productCard__ctUi5--mjlpq";
export var img__kSOL = "PlasmicHome-module--img__kSOL--1iA_i";
export var ratings___103L = "PlasmicHome-module--ratings___103L--2d9AX";
export var freeBox__a2Nr3 = "PlasmicHome-module--freeBox__a2Nr3--1goqh";
export var text__w3EXt = "PlasmicHome-module--text__w3EXt--33nKY";
export var freeBox__bsDwh = "PlasmicHome-module--freeBox__bsDwh--azrkW";
export var blogPost__wKmJh = "PlasmicHome-module--blogPost__wKmJh--3e4tL";
export var img___2Z81Z = "PlasmicHome-module--img___2Z81Z--25bB0";
export var blogPost__jehKb = "PlasmicHome-module--blogPost__jehKb--my5fj";
export var img___2ArLw = "PlasmicHome-module--img___2ArLw--36KTJ";
export var blogPost___8Vw9X = "PlasmicHome-module--blogPost___8Vw9X--3Ll4E";
export var img__rrWew = "PlasmicHome-module--img__rrWew--2wDXA";
export var freeBox__cSgXo = "PlasmicHome-module--freeBox__cSgXo--3-apV";
export var img__pyXS = "PlasmicHome-module--img__pyXS--3bh7m";
export var img__rg3O7 = "PlasmicHome-module--img__rg3O7--1TbfD";
export var img__w5Fam = "PlasmicHome-module--img__w5Fam--oSk_-";
export var img__y2LGo = "PlasmicHome-module--img__y2LGo--1WWx_";
export var img__zPGh = "PlasmicHome-module--img__zPGh--8FOTa";
export var footer = "PlasmicHome-module--footer--3SFmj";