// extracted by mini-css-extract-plugin
export var root = "PlasmicRatings-module--root--34k5e";
export var freeBox = "PlasmicRatings-module--freeBox--3EGGs";
export var svg__gwPgg = "PlasmicRatings-module--svg__gwPgg--2wZE-";
export var svg__aMaO = "PlasmicRatings-module--svg__aMaO--7WP0N";
export var svg__spGNe = "PlasmicRatings-module--svg__spGNe--1prrc";
export var svg__qnXr = "PlasmicRatings-module--svg__qnXr--QdCp-";
export var svg__auaYx = "PlasmicRatings-module--svg__auaYx--3Qk8U";
export var svg__gvxDj = "PlasmicRatings-module--svg__gvxDj--2TkYu";
export var svg__kV96 = "PlasmicRatings-module--svg__kV96--3fb-Q";
export var svg__p7Pca = "PlasmicRatings-module--svg__p7Pca--3rzH7";
export var svg__eyLSj = "PlasmicRatings-module--svg__eyLSj--iLuI1";
export var svg__glsRg = "PlasmicRatings-module--svg__glsRg--UAr2i";
export var slotTargetNumReviews = "PlasmicRatings-module--slotTargetNumReviews--1ZEWw";