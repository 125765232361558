// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--26T3N";
export var freeBox__asvnb = "PlasmicFooter-module--freeBox__asvnb--2S3mW";
export var freeBox__s7As = "PlasmicFooter-module--freeBox__s7As--eZGlG";
export var text__hkSg5 = "PlasmicFooter-module--text__hkSg5--hpadz";
export var freeBox___1HM0D = "PlasmicFooter-module--freeBox___1HM0D--FBjvJ";
export var link__eps3J = "PlasmicFooter-module--link__eps3J--nX6X2";
export var link__mJxxE = "PlasmicFooter-module--link__mJxxE--2We1Y";
export var link___1Sz7A = "PlasmicFooter-module--link___1Sz7A--2Qn00";
export var link__yB4Bn = "PlasmicFooter-module--link__yB4Bn--3ZenI";
export var freeBox__gypfh = "PlasmicFooter-module--freeBox__gypfh--R837l";
export var text__nBoGj = "PlasmicFooter-module--text__nBoGj--23-qI";
export var freeBox__kjNvI = "PlasmicFooter-module--freeBox__kjNvI--1EL-G";
export var link__lXb9X = "PlasmicFooter-module--link__lXb9X--1OJ14";
export var link__jKmsd = "PlasmicFooter-module--link__jKmsd--1V_8C";
export var link___1NaXy = "PlasmicFooter-module--link___1NaXy--HMyi4";
export var link__kpWl3 = "PlasmicFooter-module--link__kpWl3--37MAN";
export var link__sctzc = "PlasmicFooter-module--link__sctzc--3nHdA";
export var freeBox__rejGx = "PlasmicFooter-module--freeBox__rejGx--2pD9f";
export var text___8R4Uj = "PlasmicFooter-module--text___8R4Uj--1Sh6_";
export var freeBox__su5Qe = "PlasmicFooter-module--freeBox__su5Qe--3Laos";
export var link__khL4 = "PlasmicFooter-module--link__khL4--3GYH9";
export var link__rgA6F = "PlasmicFooter-module--link__rgA6F--2TZCo";
export var link__zOejd = "PlasmicFooter-module--link__zOejd--2yryB";
export var link__k9KaF = "PlasmicFooter-module--link__k9KaF--38KT9";
export var link__zraoz = "PlasmicFooter-module--link__zraoz--1REpl";
export var freeBox__jcLUg = "PlasmicFooter-module--freeBox__jcLUg--14GQ6";
export var text__nZ7Eb = "PlasmicFooter-module--text__nZ7Eb--CWpmh";
export var text__p58Nn = "PlasmicFooter-module--text__p58Nn--K9lUy";
export var freeBox__rLj8T = "PlasmicFooter-module--freeBox__rLj8T--eqhLB";
export var textbox = "PlasmicFooter-module--textbox--2Ea2E";
export var button = "PlasmicFooter-module--button--14MBM";
export var svg__guiE = "PlasmicFooter-module--svg__guiE--2r8_d";
export var freeBox__rdjCt = "PlasmicFooter-module--freeBox__rdjCt--1GSpP";
export var freeBox__dm7Nc = "PlasmicFooter-module--freeBox__dm7Nc--VgNd_";
export var iconLink__dxk9T = "PlasmicFooter-module--iconLink__dxk9T--32TNt";
export var svg__w25Cb = "PlasmicFooter-module--svg__w25Cb--1CSQb";
export var iconLink__nl1G = "PlasmicFooter-module--iconLink__nl1G--1QyTO";
export var svg__ldWh6 = "PlasmicFooter-module--svg__ldWh6--1rbyy";
export var iconLink___2ZYny = "PlasmicFooter-module--iconLink___2ZYny--3VooG";
export var svg__gtJf9 = "PlasmicFooter-module--svg__gtJf9--2q5wk";
export var freeBox__xDzPr = "PlasmicFooter-module--freeBox__xDzPr--r2r9s";
export var text__elBxi = "PlasmicFooter-module--text__elBxi--2JhSv";
export var link__z5Mqb = "PlasmicFooter-module--link__z5Mqb--ZNDsm";
export var link___7Wah = "PlasmicFooter-module--link___7Wah--gvDjb";